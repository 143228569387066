{
  "name": "personal-site",
  "version": "1.3.0",
  "private": true,
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host=127.0.0.1",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "e2e": "ng e2e",
    "commit": "git-cz",
    "release": "standard-version"
  },
  "dependencies": {
    "@angular/animations": "^18.0.0",
    "@angular/common": "^18.0.0",
    "@angular/compiler": "^18.0.0",
    "@angular/core": "^18.0.0",
    "@angular/forms": "^18.0.0",
    "@angular/localize": "^18.2.13",
    "@angular/platform-browser": "^18.0.0",
    "@angular/platform-browser-dynamic": "^18.0.0",
    "@angular/router": "^18.0.0",
    "@fortawesome/angular-fontawesome": "^1.0.0",
    "@fortawesome/fontawesome-svg-core": "^6.7.2",
    "@fortawesome/free-brands-svg-icons": "^6.7.2",
    "@fortawesome/free-regular-svg-icons": "^6.7.2",
    "@fortawesome/free-solid-svg-icons": "^6.7.2",
    "@ngx-translate/core": "^16.0.4",
    "@ngx-translate/http-loader": "^8.0.0",
    "@types/bootstrap": "^5.2.9",
    "@types/hammerjs": "^2.0.46",
    "animate.css": "^4.1.1",
    "bootstrap": "^5.3.2",
    "core-js": "^3.39.0",
    "eslit": "^6.0.0",
    "hammerjs": "^2.0.8",
    "jquery": "^3.7.1",
    "jszip": "^3.4.0",
    "ngx-cookie-service": "^18.0.0",
    "ngx-skeleton-loader": "^10.0.0",
    "ngx-ui-loader": "^13.0.0",
    "normalize.css": "^8.0.1",
    "particles.js": "^2.0.0",
    "popper.js": "^1.16.1",
    "rxjs": "^7.8.2",
    "tslib": "^2.7.0",
    "webpack": "^5.99.1",
    "xml2js": "^0.6.2",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.0",
    "@angular-eslint/builder": "18.0.0",
    "@angular-eslint/eslint-plugin": "18.0.0",
    "@angular-eslint/eslint-plugin-template": "18.0.0",
    "@angular-eslint/schematics": "18.0.0",
    "@angular-eslint/template-parser": "18.0.0",
    "@angular/cli": "^18.0.0",
    "@angular/compiler-cli": "^18.0.0",
    "@angular/language-service": "^18.0.0",
    "@microsoft/eslint-formatter-sarif": "^3.1.0",
    "@types/jasmine": "~5.1.7",
    "@types/jasminewd2": "~2.0.13",
    "@types/node": "^22.13.9",
    "@typescript-eslint/eslint-plugin": "8.16.0",
    "@typescript-eslint/parser": "8.22.0",
    "commitizen": "^4.3.1",
    "cz-conventional-changelog": "^3.3.0",
    "eslint": "^9.16.0",
    "eslint-plugin-import": "latest",
    "eslint-plugin-jsdoc": "latest",
    "eslint-plugin-prefer-arrow": "latest",
    "jasmine-core": "~5.6.0",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "protractor": "^7.0.0",
    "standard-version": "^9.5.0",
    "ts-node": "~10.9.2",
    "typescript": "~5.5.0"
  },
  "config": {
    "commitizen": {
      "path": "./node_modules/cz-conventional-changelog"
    }
  }
}
