<div class="container-fluid" style="min-height: -webkit-fill-available; overflow-y: auto;">

  <div class="col-12 text-light mh-100">
    <app-navbar></app-navbar>

    <div class="d-flex justify-content-center mt-5">
      <img NgOptimizedImage src="../../assets/img/principal-min-mob.png" alt="Perfil" id="imgPerfil" loading="lazy">
    </div>

    <div class="d-flex justify-content-center mt-5">
      <h1 id="title_name">Lucas Brito</h1>
    </div>

    <div class="d-flex justify-content-center">
      <h2 translate id="sub_title">home.sub_title</h2>
    </div>

    <hr>

    <app-icones class="mb-3"></app-icones>

  </div>
  <!--   <div id="particles-js">
    abcd
  </div> -->
</div>
