<div class="container-fluid" style="min-height: -webkit-fill-available; overflow-y: auto;">
    <app-navbar></app-navbar>

    <div class="row text-center justify-content-center mt-5">
        <h1 translate>privacy_policy.title</h1>
    </div>

    <div class="row justify-content-center mt-3">
        <h3 translate>privacy_policy.introduction</h3>
    </div>

    <div class="row justify-content-center mt-3">
        <p translate>privacy_policy.additional_text</p>
    </div>

    <div class="row justify-content-center mt-3">
        <h5 translate>privacy_policy.information_collection</h5>
        <p translate>privacy_policy.information_collection_text</p>
    </div>

    <div class="row justify-content-center mt-3">
        <h5 translate>privacy_policy.use_of_information</h5>
        <p translate>privacy_policy.use_of_information_text</p>
    </div>

    <div class="row justify-content-center mt-3">
        <h5 translate>privacy_policy.data_sharing</h5>
        <p translate>privacy_policy.data_sharing_text</p>
    </div>

    <div class="row justify-content-center mt-3">
        <h5 translate>privacy_policy.data_security</h5>
        <p translate>privacy_policy.data_security_text</p>
    </div>

    <div class="row justify-content-center mt-3">
        <h5 translate>privacy_policy.user_rights</h5>
        <p translate>privacy_policy.user_rights_text</p>
    </div>

    <div class="row justify-content-center mt-3">
        <h5 translate>privacy_policy.changes_to_policy</h5>
        <p translate>privacy_policy.changes_to_policy_text</p>
    </div>

    <div class="row justify-content-center mt-3">
        <h5 translate>privacy_policy.contact_information</h5>
        <p translate>privacy_policy.contact_information_text</p>
    </div>
</div>
