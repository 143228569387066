<div role="main" class="row" style="min-height:100%; overflow-y: auto;">
    <div class="col-lg-7 text-light mh-100">
        <!-- Navigation bar -->
        <app-navbar></app-navbar>
        <!-- Main content -->
        <div class="d-flex justify-content-center mt-5">
            <h1 id="title_name">Lucas Brito</h1> <!-- Descriptive heading with keywords -->
        </div>
        <div class="d-flex justify-content-center">
            <h2 translate id="sub_title">home.sub_title</h2> <!-- Descriptive heading -->
        </div>
        <hr>
        <!-- About section -->
        <div class="d-flex align-items-start ms-3 me-3 mt-5">
            <h5 translate>home.about</h5> <!-- Descriptive label -->
        </div>
        <!-- Descriptive text with keywords -->
        <div class="d-flex align-items-start ms-4 me-4 sobre_text">
            <p class="text-justify" [innerHTML]="'home.description' | translate: { idade: idade }"></p>
        </div>
        <!-- Icon section -->
        <app-icones></app-icones>
    </div>
    <div class="col-lg-5 mh-100 imgPrincipal">
        <!-- Placeholder for image content -->
    </div>
</div>

<!-- <div class="row" style="min-height:100%; overflow-y: auto;">
  <p translate [translateParams]="{name: 'Andreas'}">demo.greeting</p>
</div> -->
