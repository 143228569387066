<!-- portfolio.component.html -->
<div class="container mt-5">
    <div class="row">
        <div class="col-md-3">
            <h3>Filter by Tag</h3>
            <div class="list-group">
                <button type="button" class="list-group-item list-group-item-action"
                    (click)="filterByTag('All')">All</button>
                <button *ngFor="let tag of tags" type="button" class="list-group-item list-group-item-action"
                    (click)="filterByTag(tag)">{{ tag }}</button>
            </div>
        </div>
        <div class="col-md-9">
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Search Projects" aria-label="Search Projects"
                    aria-describedby="search-button" [(ngModel)]="searchQuery" (input)="searchProjects()">
                <button class="btn btn-outline-secondary" type="button" id="search-button">Search</button>
            </div>
            <div class="row">
                <ng-container *ngFor="let repo of filteredRepos">
                    <div class="col-md-4 mb-3">
                        <app-project [repo]="repo"></app-project>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>