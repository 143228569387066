<div class="container-fluid" style="min-height: -webkit-fill-available; overflow-y: auto;">
    <app-navbar></app-navbar>
    <div *ngIf="loading">
        <div class="row ps-4 pt-5 mt-5">
            <h1 translate>publi.title</h1>
        </div>
        <div class="row">
            <div translate class="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3 ps-5 px-sm-5 px-md-5 about-text">
                publi.description
                <br>
                <div class="row mt-3">
                    <div class="col">
                        <div class="alert new-info-alert" role="alert">
                            <fa-icon icon="info-circle" class="me-2"></fa-icon>
                            <small class="text-dark" translate>publi.tip</small>
                        </div>
                    </div>
                </div>
                <div class="row" style="font-size: 2rem;">
                    <a href="https://lucasbsilva29.medium.com/" class="text-white ms-2 mt-2 icon"
                        aria-label="Check my Medium" target="_blank" disableWhenOffline>
                        <fa-icon [icon]="['fab', 'medium']"></fa-icon>
                    </a>
                    <a href="https://dev.to/lucs1590" class="text-white ms-2 mt-2 icon" aria-label="Check my Dev.to"
                        target="_blank" disableWhenOffline>
                        <fa-icon [icon]="['fab', 'dev']"></fa-icon>
                    </a>
                    <a href="https://scholar.google.com.br/citations?user=lwCbcswAAAAJ&hl"
                        class="text-white ms-2 mt-2 icon" aria-label="Check my Google Scholar" target="_blank"
                        disableWhenOffline>
                        <img NgOptimizedImage [src]="scholarImage" class="img-icon"
                            (mouseover)="defineIconImage($event)" (mouseout)="defineIconImage($event)" height="28rem"
                            loading="lazy" alt="Google Scholar">
                    </a>
                    <a href="https://www.researchgate.net/profile/Lucas-Brito-Silva" class="text-white ms-2 mt-2 icon"
                        aria-label="Check my ResearchGate" target="_blank" disableWhenOffline>
                        <fa-icon [icon]="['fab', 'researchgate']"></fa-icon>
                    </a>
                </div>
            </div>
            <!-- <div>
        <img NgOptimizedImage src="../../../assets/img/writer.jpeg" class="img-fluid" alt="Writer Image"
            style="height: 15rem;width: 60rem;object-fit: cover;" loading="lazy">
    </div> -->
        </div>
    </div>

    <div *ngIf="!loading">
        <div class="row ps-3 pt-5 mt-5">
            <ngx-skeleton-loader count="1" class="thumb me-2 mb-1" [theme]="{
            'border-radius': '5px',
            'height': '3rem',
            'width': '10rem',
            'background-color': '#323232',
            'border': '1px solid #323232',
            'animation-duration': '1.2s'
            }">
            </ngx-skeleton-loader>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3 ps-5 px-sm-5 px-md-5 about-text">
                <ngx-skeleton-loader count="3" [theme]="{
                'border-radius': '5px',
                'width': '100%',
                'max-width': '30rem',
                'background-color': '#323232',
                'border': '1px solid #323232',
                'animation-duration': '1.2s'
                }"></ngx-skeleton-loader>
                <br>
                <ngx-skeleton-loader count="1" [theme]="{
                'border-radius': '5px',
                'height': '3rem',
                'width': '100%',
                'max-width': '60rem',
                'background-color': '#323232',
                'border': '1px solid #323232',
                'animation-duration': '1.2s'
                }"></ngx-skeleton-loader>
                <div class="row" style="font-size: 2rem;">
                    <ngx-skeleton-loader count="4" [theme]="{
                    'border-radius': '5px',
                    'width': '2rem',
                    'height': '2rem',
                    'background-color': '#323232',
                    'border': '1px solid #323232',
                    'margin-left': '0.5rem',
                    'animation-duration': '1.2s'
                    }"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>

    <div class="row text-white ps-3 mt-5 mb-2">
        <h2 translate>publi.blog-subtitle</h2>
    </div>
    <div class="d-flex flex-wrap text-dark justify-content-around pb-4">
        <ng-container *ngIf="loading; else skeletonTemplate">
            <div class="card real-card mb-5 text-white bg-dark border-white rounded-0 border-right-0 border-left-0"
                *ngFor="let pub of (blogPublications?.slice(0, 6) || [])">
                <div class="card-body">
                    <h3 class="card-title mb-2">{{ pub.title }}</h3>
                    <div class="card-text about-text" [innerHTML]=pub.description></div>
                    <div class="row w-100 foot-card">
                        <div class="col">
                            <a translate [href]=pub.url class="read-more" target="_blank" disableWhenOffline>
                                <fa-icon icon="link"></fa-icon> publi.read-more
                            </a>
                        </div>
                        <div class="col">
                            <p class="card-text text-end">
                                <small class="text-muted">
                                    {{ pub.publicationDate | date:'mediumDate'}}
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="blogPublications && blogPublications.length === 0" class="text-center text-white mt-5">
                <div class="alert new-info-alert" role="alert">
                    <fa-icon icon="info-circle" class="me-2"></fa-icon>
                    <span class="text-dark" translate>publi.blog-not-found</span>
                </div>
            </div>
        </ng-container>

        <ng-template #skeletonTemplate>
            <div>
                <div class="card mt-5 text-white bg-dark border-white rounded-0 border-right-0 border-left-0">
                    <div class="card-body">
                        <div class="card-title">
                            <ngx-skeleton-loader count="1" [theme]="{
                                'border-radius': '5px',
                                'width': '100%',
                                'max-width': '13rem',
                                'height': '3rem',
                                'background-color': '#323232',
                                'border': '1px solid #323232',
                                'animation-duration': '1.2s'
                            }"></ngx-skeleton-loader>
                        </div>
                        <div class="card-text">
                            <ngx-skeleton-loader count="3" [theme]="{
                                'margin-left': '1rem',
                                'border-radius': '5px',
                                'width': '100%',
                                'max-width': '21.5rem',
                                'background-color': '#323232',
                                'border': '1px solid #323232',
                                'animation-duration': '1.2s'
                            }"></ngx-skeleton-loader>
                        </div>
                        <div class="row w-100 foot-card">
                            <div class="col">
                                <ngx-skeleton-loader count="1" [theme]="{
                                    'margin-left': '1rem',
                                    'border-radius': '5px',
                                    'width': '100%',
                                    'max-width': '5rem',
                                    'background-color': '#323232',
                                    'border': '1px solid #323232',
                                    'animation-duration': '1.2s'
                                }"></ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

    <div class="row text-white ps-3 mb-2">
        <h2 translate>publi.sci-subtitle</h2>
    </div>
    <div class="d-flex flex-wrap text-dark justify-content-around pb-4">
        <ng-container *ngIf="loading; else skeletonTemplate">
            <div class="card real-card mb-5 text-white bg-dark border-white rounded-0 border-right-0 border-left-0"
                *ngFor="let pub of sciPublications">
                <div class="card-body">
                    <img NgOptimizedImage [src]="pub.image" class="thumb me-2 mb-1" alt="Thumbnail" height="2rem"
                        loading="lazy">
                    <h3 class="card-title sci-title">{{ pub.title }}</h3>
                    <div class="card-text about-text" [innerHTML]=pub.description></div>
                    <div class="row w-100 foot-card">
                        <div class="col">
                            <a translate [href]=pub.url class="read-more" target="_blank" disableWhenOffline>
                                <fa-icon icon="link"></fa-icon> publi.read-more
                            </a>
                        </div>
                        <div class="col">
                            <p class="card-text text-end">
                                <small class="text-muted">
                                    {{ pub.publicationDate | date:'mediumDate'}}
                                </small>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div *ngIf="sciPublications.length === 0" class="text-center text-white mt-5">
                <div class="alert new-info-alert" role="alert">
                    <fa-icon icon="info-circle" class="me-2"></fa-icon>
                    <span class="text-dark" translate>publi.sci-not-found</span>
                </div>
            </div>
        </ng-container>

        <ng-template #skeletonTemplate>
            <div>
                <div class="card mt-5 text-white bg-dark border-white rounded-0 border-right-0 border-left-0">
                    <div class="card-body">
                        <div class="card-title">
                            <ngx-skeleton-loader count="1" [theme]="{
                                'border-radius': '5px',
                                'width': '100%',
                                'max-width': '13rem',
                                'height': '3rem',
                                'background-color': '#323232',
                                'border': '1px solid #323232',
                                'animation-duration': '1.2s'
                            }"></ngx-skeleton-loader>
                        </div>
                        <div class="card-text">
                            <ngx-skeleton-loader count="3" [theme]="{
                                'margin-left': '1rem',
                                'border-radius': '5px',
                                'width': '100%',
                                'max-width': '21.5rem',
                                'background-color': '#323232',
                                'border': '1px solid #323232',
                                'animation-duration': '1.2s'
                            }"></ngx-skeleton-loader>
                        </div>
                        <div class="row w-100 foot-card">
                            <div class="col">
                                <ngx-skeleton-loader count="1" [theme]="{
                                    'margin-left': '1rem',
                                    'border-radius': '5px',
                                    'width': '100%',
                                    'max-width': '5rem',
                                    'background-color': '#323232',
                                    'border': '1px solid #323232',
                                    'animation-duration': '1.2s'
                                }"></ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>