import { Component } from '@angular/core';

@Component({
  selector: 'app-offline-modal',
  templateUrl: './offline-modal.component.html',
  styleUrl: './offline-modal.component.css'
})
export class OfflineModalComponent {

}
